import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import "./blog-post.css"
import Bio from "../components/bio"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import TagList from "../components/tags"

const NavLink = ({ to, rel, children }) => (
  <Link to={to} rel={rel} className="nav-link" style={{ boxShadow: `none` }}>
    {children}
  </Link>
)

const BlogPostTemplate = props => {
  const post = props.data.mdx
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <PageHeader>{post.frontmatter.title}</PageHeader>
          <p className="date">{post.frontmatter.date}</p>
          {post.frontmatter.tags && <TagList tags={post.frontmatter.tags} />}
        </header>
        <section>
          <MDXRenderer>{post.body}</MDXRenderer>
        </section>
        <hr className="my-8" />
        <footer className="mt-20">
          <Bio />
        </footer>
      </article>

      <nav>
        <ul className="flex justify-between">
          <li className="list-none">
            {previous && (
              <NavLink to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </NavLink>
            )}
          </li>
          <li className="list-none">
            {next && (
              <NavLink to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </NavLink>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  rel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`

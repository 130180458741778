import React from "react"
import PropTypes from "prop-types"

const PageHeader = ({ children }) => {
  return <h1 className="mt-8 mb-0 text-5xl md:text-6xl">{children}</h1>
}

PageHeader.propTypes = {
  children: PropTypes.string.isRequired,
}

export default PageHeader
